import { Button, Carousel } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { partners } from "../../../utils/constants/partners";

import styles from "./partners.module.scss";

const Partners = () => {
  const settings = {
    arrows: true,
    prevArrow: <Button icon={<LeftOutlined />} />,
    nextArrow: <Button icon={<RightOutlined />} />,
  };
  return (
    <Carousel autoplay={true} className={styles.container} arrows={true}>
      {partners.map((partner: string, i: number) => (
        <div key={i}>
          <h3 className={styles.contentStyle}>
            <img
              src={`/images/partners/${partner}`}
              alt={partner}
              style={
                i === 0
                  ? {
                      width: "fit-content",
                    }
                  : {}
              }
            />
          </h3>
        </div>
      ))}
    </Carousel>
  );
};
export default Partners;
