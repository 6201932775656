export const partners: string[] = [
  "acba.png",
  "evoca.png",
  "flyArna.png",
  "uls.png",
  "fit24.png",
  "pesto.png",
  "viena.png",
  "vda.png",
  "dors.png",
  "grand.png",
  "ax.png",
  "saber.png"
];
